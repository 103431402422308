export default {
    namespaced: true,
    state: {
        sidebar: false,
        sra: "schedule",
        drawer: true,
    },
    mutations: {
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        },
        drawer(state, val) {
            state.drawer = val;
        },
        changeSRA(state, sra) {
            state.sra = sra;
        },
        toggleSidebar(state) {
            state.sidebar = !state.sidebar;
        },
        toggleSidebarControl(state, size) {
            state.sidebar = size;
        },
    },
};
