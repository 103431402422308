<template>
  <div class="color_picker">
    <div class="font-14 pb-10 opacity-60">
      {{ t("atoms.room_colors") }}
    </div>
    <div class="grid">
      <div v-for="item in 20" :key="item" class="item" :class="{ selected: item == selected, item_shadow: item == selected }" :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }" @click="event(item)" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits();
const { t } = useI18n();
const props = defineProps(['modelValue']);
const selected = ref(0);

watch(() => props.modelValue, (newValue, oldValue) => {
  selected.value = props.modelValue;
});

const selection = (color_id) => {
  selected.value = color_id;
  emit("update:modelValue", color_id);
};

const event = (color_id) => {

  emit("input_Event", color_id);
  selection(color_id);
};

onMounted(() => {
 if (!props.modelValue) return selection(1);
  else selection(Number(props.modelValue));
})
</script>

<style lang="less" scoped>
.color_picker {
  position: absolute;
  width: 193px;
  height: 186px;
  bottom: -5px;
  left: 0;
  z-index: 1;
  transform: translateY(100%);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade2);
  padding: 15px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 25px 25px 25px 25px 25px;
  gap: 10px;
}

.selected {
  outline: 3px solid var(--black);
  box-sizing: border-box;
  outline-offset: -3px;
}

.item {
  height: 25px;
  width: 25px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.item_shadow {
  box-shadow: 0px 0px 0px 2px rgba(243, 243, 243, 0.5);
  -webkit-box-shadow: 0px 0px 0px 2px rgba(243, 243, 243, 0.5);
  -moz-box-shadow: 0px 0px 0px 2px rgba(243, 243, 243, 0.5);
}

.item:hover {
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.753);
}
</style>