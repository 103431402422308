import { del, get, post } from "../assets/functions";
import store from "./index";

export default {
    namespaced: true,
    state: {
        attendees: [],
        groups: [],
        edit: null,
        indexPage: 0
    },
    mutations: {
        indexPage(state, response) {
            state.indexPage = response;
        },
        editAttendee(state, response) {
            state.edit = response;
        },
        clearAttendee(state) {
            state.edit = null;
        },
        setData(state, response) {
            state.attendees = response;
        },
        setGroup(state, response) {
            state.groups = response;
        },
        editColor(state, response) {
            state.edit.color = response;
        },
    },
    actions: {
    //Attendees
        async getAttendeesInformation(context) {
            let response = await get("/api/attendees");
            context.commit("setData", response);
            return response;
        },

        async postAttendee(context, data) {
            let response = await post("/api/attendee", data);
            store.commit("notifications/raw", response);
            await context.dispatch("getAttendeesInformation");
            return response;
        },

        async updateAttendees(context, data) {
            if (!data) data = context.state.edit;
            let response = await post("/api/attendee/update", data);
            store.commit("notifications/raw", response);
            await context.dispatch("getAttendeesInformation");
            return response;
        },
        async deleteAttendee(context, val) {
            let response = await del("/api/attendee/" + val);
            store.commit("notifications/raw", response);
            context.dispatch("getAttendeesInformation");
            return response;
        },

        //Groups
        async getGroups(context) {
            let response = await get("/api/groups");
            context.commit("setGroup", response);
            return response;
        },
        async postGroup(context, data) {
            let response = await post("/api/groups", data);
            store.commit("notifications/raw", response);
            await context.dispatch("getGroups");
            return response;
        },
        async updateGroups(context, data) {
            if (!data) data = { ...context.state.edit };
            let ids = getAttendeesByID(context.state.edit.attendees);
            data.attendees = JSON.stringify(ids);
            let response = await post("/api/groups/update", data);
            store.commit("notifications/raw", response);
            await context.dispatch("getAttendeesInformation");
            return response;
        },
        async updateGroupsByID(context, data) {
            if (!data) data = context.state.edit;
            let response = await post("/api/groups/update", data);
            store.commit("notifications/raw", response);
            await context.dispatch("getAttendeesInformation");
            return response;
        },
        async deleteGroup(context, val) {
            let response = await del("/api/groups/" + val);
            store.commit("notifications/raw", response);
            context.dispatch("getAttendeesInformation");
            return response;
        },
    },
};

function getAttendeesByID(emails) {
    let users = store.state.attendees.attendees;
    return emails.map((email) => users.find((el) => el.email == email).id);
}