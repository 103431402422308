<template>
  <s-modal>
    <form class="col gap-50" @submit.prevent="post">
      <section class="header row space-between align-center">
        <span class="font-24">
          {{ action }} {{ $t("modal.addGroup.title") }}
        </span>
      </section>
      <section class="body col gap-20">
        <div class="center red--text missingText font-14" :class="{ 'green--text': message.status == 'ok' }">
          {{ message }}
        </div>
        <div class="grid align-center">
          <div class="">
            {{ $t("modal.addGroup.name") }}
          </div>
          <s-text-field v-model="name" autofocus placeholder="e.g Business Team" />
          <div class="">
            {{ $t("modal.addGroup.attendees") }}
          </div>
          <s-dropdown :height="60" :items="participants" multi @multiSelect="syncEmails" />
        </div>
        <div class="opacity-60 text-center">
          {{ $t("modal.addGroup.color") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button v-for="item in 20" :key="item" type="button" class="color" :class="{ active: item == color }" :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }" @click="selectColor(item)" />
        </div>
        <div class="wrapper gap-10 col">
          <div v-if="emails.length == 0" class="items center">
            {{ $t("modal.addGroup.no_attendee") }}
          </div>
          <div v-for="(item, index) in emails" :key="index" class="col items">
            <div class="col gap-1">
              <span> {{ index + 1 }}. {{ item.display }}</span>
              <span class="font-14 opacity-75 pl-15">
                {{ getAttendeeByEmail(item.display).name }}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section class="footer row gap-20 justify-end">
        <s-btn type="button" class="black--text" @click="close">
          {{ $t("modal.cancel") }}
        </s-btn>
        <s-btn class="green" :loading="loading">
          {{ $t("modal.save") }}
        </s-btn>
      </section>
    </form>
  </s-modal>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from 'vue-i18n';
import { useStore } from "vuex";

const { t } = useI18n();
const store = useStore();

const name = ref("");
const color = ref(1);
const participants = ref([]);
const emails = ref([]);
const loading = ref(false);
const message = ref("");
const id = ref("");

const attendees = computed(() => store.state.attendees.attendees);
const edit = computed(() => store.state.attendees.edit);

const post = async () => {
  let ids = emails.value.map(a => a.value);
  let data = {
    name: name.value,
    color: color.value,
    attendees: JSON.stringify(ids),
  };
  let haveSelected = participants.value.filter(el => el.selected).length
  if (name.value.length < 1) return message.value = t("modal.addGroup.nameAdd");
  else message.value = "";
  if (haveSelected < 2) return message.value = t("modal.addGroup.contacts");
  else message.value = "";
  if (edit.value) data.id = id.value;
  let method = edit.value ? "updateGroupsByID" : "postGroup";
  loading.value = true;
  let res = await store.dispatch(`attendees/${method}`, data);
  loading.value = false;
  store.dispatch("attendees/getGroups");
  if (res.status !== "error") return close();
  message.value = t(`server.${res.errorCode}`);
};

const close = () => {
  store.commit("modals/addGroup", false);
};

const selectColor = (val) => {
  color.value = val;
};

const syncEmails = (val) => {
  emails.value = val;
};

const getAttendeeByEmail = (email) => {
  return attendees.value.find(el => el.email == email);
};

const getAttendeesByID = (emailsList) => {
  let attendeesList = [];
  for (let email of emailsList) {
    let res = attendees.value.find(el => el.email == email);
    let data = { display: res.email, value: res.id, selected: true };
    attendeesList.push(data);
  }
  return attendeesList;
};

const populateDropdown = () => {
  participants.value = [];
  for (let x of attendees.value) {
    let res = emails.value.find(el => el.display == x.email);
    let selected = res ? true : false;
    let data = { display: x.email, value: x.id, selected };
    participants.value.push(data);
  }
};

const action = computed(() => {
  return edit.value ? t("modal.addGroup.edit") : t("modal.addGroup.create");
});

onMounted(() => {
  if (edit.value) {
    name.value = edit.value.name;
    color.value = edit.value.color;
    emails.value = getAttendeesByID(edit.value.attendees);
    id.value = edit.value.id;
  }
  populateDropdown();
})
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.items {
  font-size: 14px;
  color: #74b7e4;
  justify-content: space-between;
  max-width: 460px;
}

.wrapper {
  height: 150px;
  overflow: auto;
  border: 1px solid hsla(156, 41%, 42%, 0.2);
  box-sizing: border-box;
  background-color: var(--shade3);
  padding: 10px;
}

.color {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}
</style>