<template>
  <div ref="modal_page" class="modal_page center">
    <div ref="modal_window" class="account_modal">
      <form class="d-flex d-col space-between" @submit.prevent="validate" v-if="page == 0">
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ $t("modal.account.account") }}
          </div>
          <s-icon color="grey" @click="closeModal"> close </s-icon>
        </div>
        <div class="center mt-10 missingText red--text font-14">
          {{ message }}
        </div>
        <form ref="formAvatar" class="none">
          <input ref="fileAvatar" type="file" name="avatar" @change="upload" />
        </form>
        <section class="center">
          <div class="relative">
            <div class="profile">
              <img v-if="avatar" class="pic" :src="avatarUrl" />
              <s-icon v-if="!avatar" height="100"> account </s-icon>
              <pulse-loader :loading="load" color="white" size="10px" class="absolute" />
            </div>
            <button type="button" class="white round camera center" @click="openPicker">
              <s-icon class=""> camera </s-icon>
            </button>
          </div>
        </section>
        <div class="grid align-center mt-20">
          <div class="font-14">
            {{ $t("modal.account.email") }}
          </div>
          <s-text-field v-model="email" :disabled="!enableMCard" height="30" />
          <div class="font-14">
            {{ $t("modal.account.first_name") }}
          </div>
          <s-text-field v-model="firstName_input" height="30" />
          <div class="font-14  ">
            {{ $t("modal.account.last_name") }}
          </div>
          <s-text-field v-model="lastName_input" height="30" />
          <div class="font-14  ">
            {{ $t("modal.account.timezone") }}
          </div>
          <s-timezone v-model="timezone" />

          <div class="font-14  " v-if="!enableMCard">
            {{ $t("modal.account.current_password") }}
          </div>
          <s-text-field v-model="password" type="password" password height="30" v-if="!enableMCard"/>
          <div class="font-14  ">
            {{ $t("modal.account.new_password") }}
          </div>
          <div>
            <s-text-field v-model="newPassword" bar password height="30" type="password" />
          </div>
          <div class="font-14  ">
            {{ $t("modal.account.confirm_password") }}
          </div>
          <s-text-field v-model="confirmPassword" type="password" password height="30" />
          <div v-if="enableMCard" class="font-14  ">
            {{ $t("modal.account.phoneNumber") }}
          </div>
          <s-text-field v-if="enableMCard" v-model="phone" type="number" height="30" />
          <div class="font-14  ">
            {{ $t("modal.account.region") }}
          </div>
          <s-dropdown v-model="preferredRegion" :height="30" class="w100 item" :items="servers" />
          <div class="font-14  ">
            {{ $t("modal.account.enable_tooltip") }}
          </div>
          <s-checkbox v-model="check" @click="check = !check" />
          <div v-if="enable2fa" class="font-14  ">
            {{ twoFAText }} {{ $t("modal.account.title") }}
          </div>
          <div v-if="enable2fa" class="row space-between align-center">
            <s-btn type="button" class="green" height="30" @click="twoFA()">
              {{ twoFAText }}
            </s-btn>
          </div>
        </div>
        <div class="d-flex justify-end mt-40">
          <s-btn type="button" height="36" class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit" height="36" class="green">
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>

      <form class="d-flex d-col space-between" @submit.prevent="enterDigits" v-if="page == 1">
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.account.title") }}</div>
          <s-icon color="grey" @click="closeModal"> close </s-icon>
        </div>
        <div class="col mt-20 gap-10">
          <span>{{ $t("modal.account.subtitle") }} </span>
          <span class="weight-300 font-14">
            {{ $t("modal.account.description") }}
            <span class="weight-600"> {{ $t("modal.account.google") }}</span>
          </span>
          <img :src="qrImage" alt="" class="img mt-20" />
        </div>
        <div class="d-flex justify-end mt-40">
          <s-btn type="button" height="36" class="mr-10" @click="closeModal">
            {{ $t("modal.close") }}
          </s-btn>
          <s-btn type="button" height="36" class="mr-10 green" @click="enterDigits()">
            {{ $t("modal.next") }}
          </s-btn>
        </div>
      </form>

      <form class="d-flex d-col space-between" @submit.prevent="validateDigits" v-if="page == 2">
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.account.title") }}</div>
          <s-icon color="grey" @click="closeModal"> close </s-icon>
        </div>
        <div class="col mt-20 gap-10">
          <span>{{ $t("modal.account.sixDigits") }} </span>
          <span class="weight-300 font-14">
            {{ $t("modal.account.barcode") }}
          </span>
        </div>
        <div class="center mt-40 missingText red--text font-14">
          {{ message }}
        </div>
        <div class="row center pb-20">
          <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="6" :should-auto-focus="true" input-type="number" :conditionalClass="['one', 'two', 'three', 'four', , 'five', , 'six']" :placeholder="['*', '*', '*', '*']" @on-complete="handleOnComplete" />
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn type="button" height="36" class="mr-10" @click="closeModal">
            {{ $t("modal.close") }}
          </s-btn>
          <s-btn type="button" height="36" class="mr-10 green" @click="validateDigits()">
            {{ $t("modal.next") }}
          </s-btn>
        </div>
      </form>

      <form class="d-flex d-col space-between" @submit.prevent="logout" v-if="page == 3">
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.account.recoveryTitle") }}</div>
          <s-icon color="grey" @click="closeModal"> close </s-icon>
        </div>
        <div class="col mt-20 gap-20">
          <span>{{ $t("modal.account.recoverySubtitle") }} </span>
          <span class="font-14">{{ $t("modal.account.logoutTitle") }} </span>
          <div class="row border center w100 pa-20">
            <span class="weight-600 font-24 letter-spacing">{{ recoveryCode }}</span>
          </div>
          <span class="font-14 red--text">
            {{ $t("modal.account.recoveryWarning") }}
          </span>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn type="button" class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.close") }}
          </s-btn>
          <s-btn type="button" class="mr-10 green" @click="logout()">
            {{ $t("modal.account.logout") }}
          </s-btn>
        </div>
      </form>

    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import VOtpInput from "vue3-otp-input";
import { gsap } from "gsap";

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const otpInput = ref(null);

const firstName_input = ref("");
const lastName_input = ref("");
const password = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const message = ref("");
const timezone = ref("");
const check = ref(true);
const preferredRegion = ref("");
const load = ref(false);
const page = ref(0);
const qrImage = ref("");
const code = ref('');
const recoveryCode = ref("");
const phone = ref(null);

const modal_window = ref(null);
const modal_page = ref(null);
const formAvatar = ref(null);
const fileAvatar = ref(null);

const email = computed(() => store.state.user.email);
const enableMCard = computed(() => store.state.user.enableMCard);
const firstName = computed(() => store.state.user.firstName);
const lastName = computed(() => store.state.user.lastName);
const preferredRegionStore = computed(() => store.state.user.preferredRegion);
const avatar = computed(() => store.state.user.avatar);
const tip = computed(() => store.state.user.tip);
const enable2fa = computed(() => store.state.user.enable2fa);
const user2FA = computed(() => store.state.user.user2FA);
const twoFAText = computed(() => user2FA.value.has2FA ? t("modal.account.disable") : t("modal.account.enable"));
const avatarUrl = computed(() => process.env.VUE_APP_URL + "/api/avatar/" + avatar.value);
const servers = computed(() => {
  let data = [
    { display: t("languages.na"), value: "na" },
    { display: t("languages.eu"), value: "eu" },
  ];
  let ob = data.findIndex((el) => el.value == preferredRegionStore.value);
  data[ob].selected = true;
  return data;
});

const post = async () => {
  let data = {
    firstName: firstName_input.value,
    lastName: lastName_input.value,
    timezone: timezone.value,
    tooltips: check.value == true ? "yes" : "no",
    preferredRegion: preferredRegion.value.value,
  };

  if (password.value.length) {
    data.oldPassword = password.value;
    data.newPassword1 = newPassword.value;
    data.newPassword2 = confirmPassword.value;
  }

  if (enableMCard.value) {
    data.email = email.value;
    data.newPassword1 = newPassword.value;
    data.phone = phone.value;
  }

  store.commit("user/tip", check.value);
  let response = await store.dispatch("user/setProfile", data);

  if (response.status == "ok") return closeModal();
  let messageVal = t("server." + response.errorCode);
  if (response.status == "error") message.value = messageVal;
};

const closeModal = () => {
  gsap.to(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => {
      store.commit("modals/accountModal", false);
    },
  });
};

const validatename = () => {
  let exr = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
  let first = firstName_input.value.match(exr);
  let last = lastName_input.value.match(exr);
  let codeVal = "";
  if (!last) codeVal = "invalid_lastname";
  if (!first) codeVal = "invalid_firstname";
  if (lastName_input.value.length < 2) codeVal = "min_last_name";
  if (firstName_input.value.length < 2) codeVal = "min_first_name";
  if (lastName_input.value.length == 0) codeVal = "enter_last_name";
  if (firstName_input.value.length == 0) codeVal = "enter_first_name";
  if (!codeVal) return true;
  message.value = t("server." + codeVal);
};

const validate = () => {
  message.value = "";
  if (!validatename()) return;
  if (password.value.length > 0) {
    if (newPassword.value != confirmPassword.value) {
      message.value = t("modal.account.password_no_match");
      return;
    }
    if (newPassword.value.length < 8) {
      message.value = t("modal.account.password_min_8_char");
      return;
    }
  }
  post();
};

const validateDigits = async () => {
  let codeVal;
  if (code.value.length !== 6) return message.value = t("modal.account.codeError");
  let res = await store.dispatch("user/finish2fa", code.value);
  if (!res.recoveryCode) return message.value = t("modal.account.2fa_auth_failed");
  if (typeof res.recoveryCode == 'string') {
    codeVal = JSON.parse(res.recoveryCode);
  } else {
    codeVal = res.recoveryCode;
  }
  recoveryCode.value = codeVal.at(0);
  page.value = 3;
};

const logout = () => {
  store.commit("modals/accountModal", false);
  store.dispatch("user/logout");
  store.dispatch("calendar/clearUpcomingEvents");
};

const openPicker = () => {
  fileAvatar.value.click();
};

const enterDigits = () => {
  page.value = 2;
};

const upload = async (data) => {
  let formData = new FormData(formAvatar.value);
  load.value = true;
  await store.dispatch("user/setProfilePicture", formData);
  await store.dispatch("user/getUserInformation");
  load.value = false;
};

const twoFA = async (data) => {
  if (user2FA.value?.has2FA) return store.dispatch("user/cancel2fa");
  let res = await store.dispatch("user/enable2fa");
  if (res.status !== "ok") return;
  page.value = 1;
  qrImage.value = res.data;
};

const handleOnComplete = (value) => {
  code.value = value
};



onMounted(() => {
  firstName_input.value = firstName.value;
  lastName_input.value = lastName.value;
  check.value = tip.value;

  gsap.from(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });

  gsap.from(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
});
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade1);
  color: var(--black);
  overflow: auto;
  max-height: -webkit-fill-available;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.strength_container {
  grid-column-gap: 4px;
  min-height: 2px;
  margin-top: 5px;
}

.strength {
  width: 72px;
  height: 2px;
  grid-column-gap: 5px;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.red {
  background-color: #df5151;
}

.orange {
  background-color: #df9851;
}

.green {
  background-color: #55af6d;
}

.blue {
  background-color: #517cdf;
}

.img {
  max-height: 300px;
  min-height: 300px;
  height: 100%;
  object-fit: contain;
}

.confirmCode {
  border: rgba(255, 255, 255, 0.288) solid 1px;
  width: 100%;
  height: 50px;
  background: rgba(253, 253, 253, 0);
  text-align: center;
  color: white;
  font-size: 24px;
}

.border {
  border: 1px solid #9f9f9f;
}

:deep(.otp-input)  {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  color: white;
}

:deep(.otp-input.is-complete)   {
  background-color: #e4e4e410;
}

:deep(.otp-input::-webkit-inner-spin-button)   ,
:deep( .otp-input::-webkit-outer-spin-button) 
 {
  -webkit-appearance: none;
  margin: 0;
}

:deep(input::placeholder)  {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>