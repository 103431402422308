<template>
  <div class="bulgy-radios d-flex align-center wrap" :class="{ vertical: props.vertical === '' }" role="radiogroup" aria-labelled-by="bulgy-radios-label">
    <label v-for="(item, index) in props.options" :key="index" @click="select(index)">
      <button type="button">
        <input type="radio" name="options" :checked="modelValue === index">
        <span class="radio" />
        <span class="label">{{ item }}</span>
      </button>
    </label>
  </div>
</template>

<script setup>
const props = defineProps(['vertical', 'options, modelValue']);
const emit = defineEmits();

const select = (index) => {
  emit('update:modelValue', index);
};
</script>

<style scoped lang='less'>
.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.bulgy-radios label {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--black);
  letter-spacing: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bulgy-radios label:hover input:not(:checked)~.radio {
  opacity: 0.8;
}

.bulgy-radios .label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 1rem;
}

.bulgy-radios .label span {
  line-height: 1em;
}

.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -2000px;
  display: none;
}

.bulgy-radios input:checked~.radio {
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.bulgy-radios input:checked~.radio::after {
  opacity: 1;
}

.bulgy-radios input:checked~.label {
  color: var(--black);
}

.radio {
  position: absolute;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid white;
}

.radio::after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0ac07d;
}

@media only screen and (max-width: 576px) {
  .bulgy-radios {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>