<template>
  <section class="modalContainer">
    <Meeting v-if="meeting" />
    <Account v-if="account" />
    <Drawer />
    <Attendee v-if="attendee" />
    <Room v-if="room" />
    <Timezone v-if="timezone" />
    <Repeating v-if="repeating" />
    <DeleteEventConfirmation v-if="deleteEventConfirmation" />
    <DeleteAttendeeConfirmation v-if="deleteAttendeeConfirmation" />
    <DeleteRoomConfirmation v-if="deleteRoomConfirmation" />
    <UpdateAttendeeConfirmation v-if="updateAttendeeConfirmation" />
    <UpdateRoomsConfirmation v-if="updateRoomsConfirmation" />
    <FirstTimeWizard v-if="firstTimeWizard" />
    <SaveEventConfirmation v-if="saveEventConfirmation" />
    <DeleteEventSimpleConfirmation v-if="deleteEventSimpleConfirmation" />
    <AddGroup v-if="addGroup" />
    <AppInfo v-if="appInfo" />
    <MaxRooms v-if="maxRooms" />
    <updateProfileComp v-if="updateProfile" />
    <Auth2fa v-if="auth2faComputed" />
    <Notifications />
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Meeting from "@/components/Modals/NewMeeting";
import Account from "@/components/Modals/Account";
import Attendee from "@/components/Modals/AddAttendee";
import AddGroup from "@/components/Modals/AddGroup";
import Room from "@/components/Modals/AddRoom";
import Timezone from "@/components/Modals/Timezone";
import Repeating from "@/components/Modals/Repeating";
import Drawer from "@/components/Toolbars/Drawer";
import DeleteEventConfirmation from "@/components/Modals/DeleteEventConfirmation";
import DeleteAttendeeConfirmation from "@/components/Modals/DeleteAttendeeConfirmation";
import DeleteRoomConfirmation from "@/components/Modals/DeleteRoomConfirmation";
import FirstTimeWizard from "@/components/Modals/FirstTimeWizard";
import UpdateAttendeeConfirmation from "@/components/Modals/UpdateAttendeeConfirmation";
import UpdateRoomsConfirmation from "@/components/Modals/UpdateRoomsConfirmation";
import Notifications from "@/components/Modals/Notifications";
import SaveEventConfirmation from "@/components/Modals/SaveEventConfirmation";
import DeleteEventSimpleConfirmation from "@/components/Modals/DeleteEventSimpleConfirmation";
import AppInfo from "@/components/Modals/AppInfo.vue";
import MaxRooms from "@/components/Modals/MaxRooms.vue";
import updateProfileComp from "@/components/Modals/updateProfile.vue";
import Auth2fa from "@/components/Modals/Auth2fa.vue";

const store = useStore();
const updateProfile = computed(() => store.state.modals.updateProfile);
const account = computed(() => store.state.modals.account);
const meeting = computed(() => store.state.modals.meeting);
const billing = computed(() => store.state.modals.billing);
const attendee = computed(() => store.state.modals.attendee);
const addGroup = computed(() => store.state.modals.addGroup);
const room = computed(() => store.state.modals.room);
const timezone = computed(() => store.state.modals.timezone);
const repeating = computed(() => store.state.modals.repeating);
const deleteEventConfirmation = computed(() => store.state.modals.deleteEventConfirmation);
const deleteRoomConfirmation = computed(() => store.state.modals.deleteRoomConfirmation);
const deleteAttendeeConfirmation = computed(() => store.state.modals.deleteAttendeeConfirmation);
const firstTimeWizard = computed(() => store.state.modals.firstTimeWizard);
const updateAttendeeConfirmation = computed(() => store.state.modals.updateAttendeeConfirmation);
const updateRoomsConfirmation = computed(() => store.state.modals.updateRoomsConfirmation);
const NotificationsComputed = computed(() => store.state.modals.Notifications); 
const rateExperience = computed(() => store.state.modals.rateExperience);
const saveEventConfirmation = computed(() => store.state.modals.saveEventConfirmation);
const deleteEventSimpleConfirmation = computed(() => store.state.modals.deleteEventSimpleConfirmation);
const appInfo = computed(() => store.state.modals.appInfo);
const maxRooms = computed(() => store.state.modals.maxRooms);
const auth2faComputed = computed(() => store.state.modals.auth2fa); 
</script>