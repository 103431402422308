<template>
  <transition name="fade" appear @before-leave="beforeLeave">
    <div class="modal-page center overlay">
      <transition name="scale" appear>
        <section ref="el" class="modal-window">
          <FocusLoop>
            <slot />
          </FocusLoop>
        </section>
      </transition>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue';

const el = ref(null);

const beforeLeave = () => {
  el.value.classList.add("transitions");
  el.value.style.transform = "scale(0.7)";
};

</script>


<style scoped>
.modal-page {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.4);
  z-index: 8;
}

.modal-window {
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 var(--shade3);
  background-color: var(--shade1);;
  min-width: 450px;
  max-width: 450px;
  width: 100%;
  border-radius: 15px;
}

@media only screen and (max-width: 576px) {
  .modal-window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    min-width: auto;
    max-width: none;
  }
}
</style>