<template>
  <FocusLoop>
    <div class="modal_page center">
      <form class="modal_window d-flex d-col space-between" @submit.prevent="post">
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ $t("modal.deleteEvent.deleteEvent") }}
          </div>
          <s-icon color="grey" @click="closeModal">
            close
          </s-icon>
        </div>

        <div class="pt-30 opacity-60 font-14">
          {{ $t("modal.deleteEvent.confirm") }}
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn type="button"  class="mr-10 black--text" @click="cancel">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit"  class="red">
            {{ $t("modal.delete") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { gsap } from "gsap";
import { useStore } from 'vuex';

const store = useStore();

const post = async () => {
    await store.dispatch("schedule/deleteEventByID");
    await store.dispatch("calendar/goToLastCalendarView");
    gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });
    gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: onComplete,
    });
};

const onComplete = () => {
    store.commit("modals/deleteEventSimpleConfirmation", false);
};

const cancel = () => {
    closeModal();
};

const closeModal = () => {
    gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });
    gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: onComplete,
    });
};

const edit = computed(() => store.state.rooms.edit);

onMounted(() => {
    gsap.from(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });

    gsap.from(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
    });
});
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade1);
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>