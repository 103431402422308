<template>
  <div class="bulgy-radios d-flex align-center wrap" role="radiogroup" aria-labelledby="bulgy-radios-label">
    <button type="button" @click="emitValue('one')">
      <label :title="$t('schedule.tip.password1')">
        <input type="radio" name="options" :checked="props.modelValue == 'one'">
        <span class="radio" />
        <span class="label"> {{ $t("atoms.no_password") }}</span>
      </label>
    </button>
    <button type="button" @click="emitValue('two')">
      <label :title="$t('schedule.tip.password2')">
        <input type="radio" name="options" :checked="props.modelValue == 'two'">
        <span class="radio" />
        <span class="label">{{ $t("atoms.custom_password") }}</span>
      </label>
    </button>
    <button type="button" @click="emitValue('three')">
      <label :title="$t('schedule.tip.password3')">
        <input type="radio" name="options" :checked="props.modelValue == 'three'">
        <span class="radio" />
        <span class="label pr-0">{{ $t("atoms.generate_password") }}</span>
      </label>
    </button>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits();

const emitValue = (val) => {
  emit('update:modelValue', val);
}

onMounted(() => {
  emitValue('one')
});
</script>

<style scoped lang='less'>
.bulgy-radios label {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgb(170, 170, 170);
  letter-spacing: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bulgy-radios label:hover input:not(:checked)~.radio {
  opacity: 0.9;
}

.bulgy-radios .label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 1rem;
}

.bulgy-radios .label span {
  line-height: 1em;
}

.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -2000px;
  display: none;
}

.bulgy-radios input:checked~.radio {
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  border: 1px solid var(--primary);
}

.bulgy-radios input:checked~.radio::after {
  opacity: 1;
  
}

.bulgy-radios input:checked~.label {
  color: #000;
}

.radio {
  position: absolute;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid var(--shade5);
}

.radio::after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0ac07d;
}

button:focus {
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .bulgy-radios {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>