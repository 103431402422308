<template>
  <FocusLoop>
    <div ref="modal_page" class="modal_page center">
      <form ref="modal_window" class="account_modal d-flex d-col space-between" @keyup.enter="post" @submit.prevent="post">
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ action }} {{ $t("modal.addRoom.room") }}
          </div>
          <s-icon color="grey" @click="closeModal">
            close
          </s-icon>
        </div>
        <div class="center mt-10 missingText red--text font-14">
          {{ message }}
        </div>
        <div class="grid align-center mt-20">
          <div class="font-14 opacity-60">
            {{ $t("modal.addRoom.name") }}
          </div>
          <s-text-field ref="nameRef" v-model="name" autofocus />
        </div>
        <div class="opacity-60 mt-20 mb-20 text-center">
          {{ $t("modal.addRoom.assign") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button v-for="item in 20" :key="item" type="button" class="color" :class="{ active: item == selected }" :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }" @click="select(item)" />
        </div>
        <div class="d-flex justify-end mt-40">
          <div v-if="!edit" class="center mr-auto pointer noSelect" @click="check = !check">
            <s-checkbox v-model="check" />
            <span class="font-14 ml-10">
              {{ $t("modal.keep_open") }}
            </span>
          </div>
          <s-btn type="button" class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn v-if="edit" type="button"  class="red mr-10" @click="del">
            {{ $t("modal.delete") }}
          </s-btn>
          <s-btn :disabled="block" type="submit"  class="green">
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { gsap } from "gsap";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();

const name = ref("");
const selected = ref(1);
const message = ref("");
const block = ref(false);
const check = ref(false);
const modal_page = ref(null);
const modal_window = ref(null);
const nameRef = ref(null);

const edit = computed(() => store.state.rooms.edit);
const rooms = computed(() => store.state.rooms.rooms);
const action = computed(() => {
  return edit.value ? t("modal.addRoom.edit") : t("modal.addRoom.create");
});

const post = async () => {
  message.value = "";
  nameRef.value.onFocus();
  if (validate()) return;
  let data = {
    title: name.value,
    color: selected.value,
  };
  let response;
  if (edit.value) {
    let ob = rooms.value.find(el => search(el));
    if (ob) return notification();
    data.id = edit.value.id;
    response = await store.dispatch("rooms/updateRoom", data);
  } else {
    block.value = true;
    response = await store.dispatch("rooms/postRoom", data);
    if (response.status == "error") {
      block.value = false;
      return store.commit("notifications/push", {
        error: t("server." + response.errorCode),
      });
    }
  }
  if (response.error) message.value = response.errorCode;
  if (!response.error && !check.value) closeModal();
  store.dispatch("rooms/getRoomsInformation");
  store.dispatch("calendar/getCalendarEvents");
  block.value = false;

  if (!response.error) {
    name.value = "";
    selected.value = 1;
  }
};

const del = () => {
  store.commit("rooms/editRoom", edit.value);
  store.commit("modals/deleteRoomConfirmationModal", true);
  closeModal();
};

const select = (val) => {
  selected.value = val;
};

const validate = () => {
  if (name.value.length > 0) return;
  message.value = t("modal.addRoom.room_name");
  return true;
};

const search = (el) => {
  return el.title == name.value && el.id !== edit.value.id;
};

const notification = () => {
  return store.commit("notifications/push", {
    error: t("server.room_already_exists"),
  });
};

const closeModal = () => {
  gsap.to(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => {
      store.commit("modals/roomModal", false);
      block.value = false;
    },
  });
};

onMounted(() => {
  if (edit.value) {
    name.value = edit.value.title;
    selected.value = edit.value.color;
  }

  gsap.from(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });

  gsap.from(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
});
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade1);;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>