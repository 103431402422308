import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { del, get, post } from "../assets/functions";
import router from "../router";
import store from "./index";
dayjs.extend(dayOfYear);
dayjs.extend(customParseFormat);
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const getDefaultState = () => {
    return {
        schedule: [],
        upcoming: [],
        id: "",
        title: "",
        date: "",
        time: "",
        duration: "",
        password: "",
        notification: [],
        rooms: "",
        description: "",
        attendees: '[]',
        groups: '[]',
        timezones: "",
        repeating: "none",
        repeatingUID: "",
        error: {},
        meetingLink: "",
        originalpassword: "",
        originalDateTime: "",
        datetime: "",
        custom: false,
        pastEvent: false,
        showAttendees: true,
        checked: 'one',
        step: 0,
        userError: ""
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        increment(state) {
            if (state.step + 1 > 4) return;
            state.step = state.step + 1;
        },
        decrement(state) {
            if (state.step - 1 < 0) return;
            state.step = state.step - 1;
        },
        setStep(state, val) {
            state.step = val;
        },
        setUserError(state, val) {
            state.userError = val;
        },
        setLink(state, val) {
            state.meetingLink = val;
        },
        setNotification(state, val) {
            state.notification = val;
        },
        setError(state, val) {
            state.error = val;
        },
        setRepeating(state, val) {
            state.repeating = val;
        },
        setRepeatingUID(state, val) {
            state.repeatingUID = val;
        },
        setTimezone(state, val) {
            state.timezones = val;
        },
        setAttendees(state, val) {
            state.attendees = val;
        },
        setGroups(state, val) {
            state.groups = val;
        },
        setDescription(state, val) {
            state.description = val;
        },
        setID(state, val) {
            state.id = val;
        },
        setRoom(state, val) {
            state.rooms = val;
        },
        setShowAttendees(state, val) {
            state.showAttendees = val;
        },
        setChecked(state, val) {
            state.checked = val;
        },
        setTitle(state, val) {
            state.title = val;
        },
        setPassword(state, val) {
            state.password = val;
        },
        setOriginalPassword(state, val) {
            state.originalpassword = val;
        },
        setOriginalDateTime(state, val) {
            state.originalDateTime = val;
        },
        setDuration(state, val) {
            state.duration = val;
        },
        setData(state, response) {
            state.schedule = response;
        },
        pastEvent(state, response) {
            state.pastEvent = response;
        },

        setDatetimeDate(state, val) {
            let day = dayjs(state.datetime);
            if (!day.isValid()) day = dayjs();
            day = day.dayOfYear(val.dayOfYear());
            day = day.year(val.year());
            state.datetime = day.format();
        },
        setDatetimeTime(state, val) {
            let day = dayjs(state.datetime);
            if (!day.isValid()) day = dayjs();
            day = day.hour(dayjs(val).hour());
            day = day.minute(dayjs(val).minute());
            state.datetime = day.format();
        },
        setDatetime(state, response) {
            state.datetime = response;
        },
        custom(state, val) {
            state.custom = val;
        },
    },
    actions: {
        async getScheduleInformation(context) {
            let response = await get("/api/events");
            context.commit("setData", response);
        },
        async getEventDetailsByID(context, data) {
            let response = await get("/api/event/" + data.id);
            let start = dayjs.unix(response.event.dataValues.from).tz(response.event.timezone);
            let to = dayjs.unix(response.event.dataValues.to).tz(response.event.timezone, true);
            if (data.end) to = dayjs.unix(data.end).tz(response.event.timezone, true);
            let diff = to.diff(start, "m");
            let hours = Math.floor(diff / 60);
            let minutes = diff % 60;
            if (String(minutes).length == 1) minutes = minutes + "0";
            let duration = hours + ":" + minutes;
            if (data.date) start = dayjs(data.date).format();
            if (data.end) to = dayjs.unix(data.end);
            if (!response.event.dataValues.repeatType) response.event.dataValues.repeatType = "none";
            let pastEvent = dayjs(start).tz(response.event.dataValues.timezone);
            pastEvent = pastEvent.isBefore(dayjs().tz(response.event.dataValues.timezone));
            context.commit("pastEvent", pastEvent);
            context.commit("setRoom", response.event.dataValues.room);
            context.commit("setDuration", duration);
            context.commit("setDatetime", start);
            context.commit("setOriginalDateTime", start);
            context.commit("setRepeating", response.event.dataValues.repeatType);
            context.commit("setShowAttendees", response.event.dataValues.showAttendees);
            context.commit("setRepeatingUID", response.event.dataValues.repeat);
            context.commit("setTitle", response.event.dataValues.title);
            context.commit("setID", response.event.dataValues.id);
            context.commit("setPassword", response.event.dataValues.password);
            context.commit("setOriginalPassword", response.event.dataValues.password);
            context.commit("setDescription", response.event.dataValues.description);
            context.commit("setAttendees", JSON.stringify(response.attendees));
            context.commit("setGroups", JSON.stringify(response.groups));
            context.commit("setTimezone", response.event.dataValues.timezone);
            context.commit("setNotification", response.notifications);
            return response;
        },
        async deleteEventByID(state) {
            let response = await del("/api/event/" + state.state.id);
            store.commit("notifications/raw", response);
            store.dispatch("calendar/getUpcomingEvents");
        },
        async deleteEventByUID(state) {
            let response = await del("/api/event/" + state.state.repeatingUID);
            store.commit("notifications/raw", response);
            store.dispatch("calendar/getUpcomingEvents");
        },
        async postSchedule(state) {
            let from = dayjs(state.state.datetime).tz(state.state.timezones, true);
            let body = {
                title: state.state.title,
                from: from.unix(),
                duration: state.state.duration,
                repeat: state.state.repeating,
                description: state.state.description,
                repeatType: "all",
                showAttendees: state.state.showAttendees,
                room: state.state.rooms,
                attendees: state.state.attendees,
                groups: state.state.groups,
                password: state.state.password,
                timezone: state.state.timezones,
                notifications: JSON.stringify(state.state.notification),
            };
            let response = await post("/api/event", body);
            store.commit("notifications/raw", response);
            store.dispatch("calendar/getUpcomingEvents");
            return;
        },
        async updateEvent(state, options) {
            let from = dayjs(state.state.datetime).tz(state.state.timezones, true);
            let body = {
                id: state.state.id,
                title: state.state.title,
                from: from.unix(),
                duration: state.state.duration,
                repeat: state.state.repeating,
                description: state.state.description,
                room: state.state.rooms,
                showAttendees: state.state.showAttendees,
                attendees: state.state.attendees,
                groups: state.state.groups,
                password: state.state.password,
                timezone: state.state.timezones,
                notifications: JSON.stringify(state.state.notification),
            };
            if (options?.allEvents) body.repeatUpdate = "all";
            if (options?.allEvents) body.repeat = state.state.repeatingUID;
            let response = await post("/api/event/update", body);
            store.commit("notifications/raw", response);
            let memoryDate = store.state.calendar.date.format("YYYY-MM-DD");
            let memoryView = store.state.calendar.view;
            let url = "/calendar/" + memoryView + "/" + memoryDate;
            if (router.currentRoute.name !== "CalendarDate") router.push(url);
            store.dispatch("calendar/getUpcomingEvents");
            return;
        },
        async getMeetingLink(context) {
            let response = await get("/api/event/link/" + context.state.id);
            context.commit("setLink", response.url);
        },
    },
};
