<template>
  <div id="app relative">
  <router-view :key="router.currentRoute" />
      <ModalController />
  </div>
</template>

<script setup>
import { computed,  onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ModalController from "@/components/Modals/Assets/Controller.vue";

const store = useStore();
const router = useRouter();

const email = computed(() => store.state.user.email);

const initUserAccount = () => {
    store.commit("calendar/loadData");
    store.dispatch("calendar/innitGetUpcomingEvents");
};

const initGuestAccount = () => {
    store.dispatch("user/getLangGuest");
};

const initCloudOrOnPremise = () => {
    let onpremise = process.env.VUE_APP_BUILD_TYPE !== "cloud";
    store.commit("user/onpremise", onpremise);
};

onMounted(() => {
    initCloudOrOnPremise();
    if (email.value) initUserAccount();
    if (!email.value) initGuestAccount();
    store.dispatch("user/getUserInformation");
    store.dispatch("user/getLocation");
    store.dispatch("user/getConfiguration");
    store.commit("user/setTheme");
    store.commit("modals/resetState");
});

</script>


