<template>
  <FocusLoop>
    <div class="modal_page center">
      <form class="modal_window d-flex d-col space-between" @submit.prevent="post">
        <div class="d-flex space-between align-center">
          <div class="font-24">
            {{ action }} {{ $t("modal.addAttendee.attendee") }}
          </div>
        </div>
        <div class="center mt-10 red--text missingText font-16" :class="{ 'green--text': message.status == 'ok' }">
          {{ message.msg }}
        </div>
        <div class="grid align-center mt-20">
          <div class="">
            {{ $t("modal.addAttendee.name") }}
          </div>
          <s-text-field v-model="name" autofocus />
          <div class="">
            {{ $t("modal.addAttendee.email") }}
          </div>
          <s-text-field v-model="email" :error="error" />
          <div class="">
            {{ $t("modal.addAttendee.phone") }}
          </div>
          <s-text-field v-model="phone" />
          <div class="tz">
            {{ $t("modal.addAttendee.timezone") }}
          </div>
          <s-timezone v-model="timezone" :height="60" :prefill="edit && edit.timezone" class="tz"/>
          <!-- <div class="lang">
            {{ $t("modal.addAttendee.language") }}
          </div>
          <s-dropdown v-model="language" :height="60" class="w100 lang" :items="items" /> -->
        </div>


        <div class="opacity-60 mt-40 mb-40 text-center">
          {{ $t("modal.addAttendee.color") }}
        </div>
        <div class="w100 d-flex wrap center">
          <button v-for="item in 20" :key="item" type="button" class="color" :class="{ active: item == selected }" :style="{ backgroundColor: 'var(--sm-color-' + item + ')' }" @click="select(item)" />
        </div>
        <div class="d-flex mt-40 justify-end">
          <div v-if="!edit" class="center mr-auto pointer noSelect" @click="check = !check">
            <s-checkbox v-model="check" />
            <span class="font-16 ml-10">
              {{ $t("modal.keep_open") }}
            </span>
          </div>
          <s-btn type="button"  class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn v-if="edit" type="button"  class="red mr-10" @click="del">
            {{ $t("modal.delete") }}
          </s-btn>
          <s-btn :disabled="block" type="submit"  class="green">
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { gsap } from 'gsap';
import { useI18n } from 'vue-i18n';
import { useMq } from "vue3-mq";
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const mq = useMq();
const { t } = useI18n();

const name = ref("");
const email = ref("");
const phone = ref("");
const language = ref("");
const timezone = ref("");
const selected = ref(1);
const message = ref("");
const block = ref(false);
const error = ref("");
const check = ref(false);
const edit = computed(() => store.state.attendees.edit);

const post = async () => {
  error.value = "";
  let res;
  let data = {
    name: name.value,
    email: email.value,
    phone: phone.value,
    color: selected.value,
    lang: language.value,
    timezone: timezone.value,
  };

  if (!validateEmail())
    return (error.value = t("modal.addAttendee.valid"));

  if (edit.value) {
    block.value = true;
    data.id = edit.value.id;
    if (data.lang) data.lang = data.lang.value;
    res = await store.dispatch("attendees/updateAttendees", data);
    if (res.status == "error") {
      message.value = {
        msg: t(`server.${res.errorCode}`),
        status: res.status,
      };
    }
    if (res.status == "ok") return closeModal();
    block.value = false;
  } else {
    block.value = true;
    if (data.lang) data.lang = data.lang.value;
    res = await store.dispatch("attendees/postAttendee", data);

    block.value = false;
    if (res.status == "error") {
      message.value = {
        msg: t(`server.${res.errorCode}`),
        status: res.status,
      };
    } else {
      message.value = {
        msg: `${email.value} ` + t(`modal.addAttendee.successfully`),
        status: "ok",
      };
      if (!check.value) return closeModal();
    }
  }
  store.dispatch("attendees/getAttendeesInformation");
  if (res.status == "ok") {
    name.value = "";
    email.value = "";
    phone.value = "";
    selected.value = 1;
  }
};

const select = (val) => {
  selected.value = val;
};

const del = () => {
  gsap.to(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => {
      store.commit("modals/attendeeModal", false);
      store.commit("modals/deleteAttendeeConfirmationModal", true);
      block.value = false;
    },
  });
};

const validateEmail = () => {
  let regex =
    /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email.value);
};

const closeModal = () => {
  gsap.to(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => {
      store.commit("modals/attendeeModal", false);
      block.value = false;
    },
  });
};

const items = computed(() => {
  let lang = [
    { display: t("languages.en"), value: "en" },
    { display: t("languages.de"), value: "de" },
    { display: t("languages.fr"), value: "fr" },
    { display: t("languages.sk"), value: "sk" },
    { display: t("languages.pl"), value: "pl" },
    { display: t("languages.el"), value: "el" },
    { display: t("languages.es"), value: "es" },
    { display: t("languages.nl"), value: "nl" },
    { display: t("languages.fr"), value: "fr" },
    { display: t("languages.hi"), value: "hi" },
    { display: t("languages.zh-cn"), value: "zh-cn" },
    { display: t("languages.zh-tw"), value: "zh-tw" },
    { display: t("languages.pt"), value: "pt" },
  ];
  if (!edit.value) return lang;
  let find = lang.find((el) => el.value == edit.value.lang);
  if (!find) lang[0].selected = true;
  else find.selected = true;
  return lang;
});

const action = computed(() => {
  return edit.value
    ? t("modal.addAttendee.edit")
    : t("modal.addAttendee.create");
});

onMounted(() => {
  gsap.from(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.from(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
  if (edit.value) {
    name.value = edit.value.name;
    email.value = edit.value.email;
    phone.value = edit.value.phone;
    selected.value = edit.value.color;
    timezone.value = edit.value.timezone;
    language.value = edit.value.language;
  }
})
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
  overflow: overlay;
}

.modal_window {
  width: 650px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 var(--shade3);
  background-color: var(--shade1);
  border-radius: 15px;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
}

.color {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--green);
}

.active {
  box-shadow: 0 0 20px var(--green);
  border: 1px solid rgb(255, 255, 255);
}

.lang{
  padding-top: 20px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>