<template>
    <SModal>
        <form class="col " @submit.prevent="post">
            <div class="d-flex space-between align-center">
                <div class="font-18">{{ $t("modal.account.title") }}</div>
                <s-icon color="grey" @click="closeModal"> close </s-icon>
            </div>
            <div class="col mt-20 gap-10">
                <span>{{ $t("modal.account.auth2FAcode") }} </span>
                <span class="weight-300 font-14">
                    {{ $t("modal.account.authMain") }}
                </span>
            </div>
            <div class="center mt-40 missingText red--text font-14">
                {{ message }}
            </div>
            <div class="row py-50 gap-10">
                <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :value="''" :num-inputs="6" :should-auto-focus="true" input-type="number" :conditionalClass="['one', 'two', 'three', 'four', , 'five', , 'six']" :placeholder="['*', '*', '*', '*']" @on-complete="handleOnComplete" />
            </div>
            <div class="d-flex justify-end mt-40">
                <s-btn type="button" height="36" class="mr-10" @click="closeModal">
                    {{ $t("modal.close") }}
                </s-btn>
                <s-btn type="submit" height="36" class="mr-10 green">
                    {{ $t("modal.verify") }}
                </s-btn>
            </div>
        </form>
    </SModal>
</template>
  
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VOtpInput from "vue3-otp-input";

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const message = ref("");
const code = ref("");

const cred = computed(() => store.state.user.cred);

const post = async () => {
    if (code.value.length !== 6) return message.value = t("modal.account.codeError");
    let res = await store.dispatch("user/check2fa", code.value);
    if (res.status == 401 || res.status == "error") return message.value = t("modal.account.wrongCode");
    let resLogin = await store.dispatch("auth/login", cred.value);
    if (resLogin.status == 'error') message.value = resLogin.message
    await store.dispatch("user/getUserInformation");
    store.commit("calendar/loadData");
    store.dispatch("rooms/getRoomsInformation");
    store.dispatch("attendees/getAttendeesInformation");
    store.dispatch("user/getTimezones");
    store.dispatch("user/checkIfAccountIsOutOfTrial");
    store.dispatch("calendar/innitGetUpcomingEvents");
    store.dispatch("user/getConfiguration");
    router.push("/");
    store.commit("user/cred", {});
    closeModal();
};

const closeModal = () => {
    store.commit("modals/auth2fa", false);
};

const handleOnComplete = (newCode) => {
    code.value = newCode;
};
</script>
  
<style scoped>
.border {
    border: 1px solid #9f9f9f;
}

:deep(.otp-input)  {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  color: white;
}

:deep(.otp-input.is-complete)   {
  background-color: #e4e4e410;
}

:deep(.otp-input::-webkit-inner-spin-button)   ,
:deep( .otp-input::-webkit-outer-spin-button) 
 {
  -webkit-appearance: none;
  margin: 0;
}

:deep(input::placeholder)  {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>