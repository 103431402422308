
import VTooltip from "v-tooltip";
import VueClipboard from "vue-clipboard2";
import resize from "vue-element-resize-detector";
import { FocusLoop } from '@vue-a11y/focus-loop'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import clickOutside from "./assets/directives";
import { Vue3Mq } from "vue3-mq";
import HotkeyPlugin from 'v-hotkey3'

import Icon from "@/components/Atoms/Icon.vue";
import Radio from "@/components/Atoms/Radio.vue";
import Button from "@/components/Atoms/Button.vue";
import Dropdown from "@/components/Atoms/Dropdown.vue";
import Calendar from "@/components/Atoms/Calendar.vue";
import Checkbox from "@/components/Atoms/Checkbox.vue";
import Timezone from "@/components/Atoms/Timezone.vue";
import TextArea from "@/components/Atoms/TextArea.vue";
import Animation from "@/components/Atoms/AnimatedLogo";
import TextField from "@/components/Atoms/TextField.vue";
import DatePicker from "@/components/Atoms/DatePicker.vue";
import TimePicker from "@/components/Atoms/TimePicker.vue";
import ColorPicker from "@/components/Atoms/ColorPicker.vue";
import ModalComp from "@/components/Modals/Assets/ModalComp.vue";

import { createApp } from 'vue'
import App from "./App.vue";
import router from "./router";
import i18n from "./assets/i18n";
import store from './store'

import "@/assets/global.less";
import "@/assets/helper.less";
import "@/assets/spacing.less";
import "@/assets/animations.less";
import "@/assets/color.less";
import "@/assets/size.less";
import "@/assets/absoluteSpacing.less";

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(store)
app.use(resize);
app.use(VTooltip);
app.use(VueClipboard);
app.use(HotkeyPlugin)
app.use(Vue3Mq, {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1200,
        xl: 1904,
    }
})

app.config.productionTip = false;

app.component("SIcon", Icon);
app.component("SBtn", Button);
app.component("SRadio", Radio);
app.component("SLogo", Animation);
app.component("SDropdown", Dropdown);
app.component("SCalendar", Calendar);
app.component("SCheckbox", Checkbox);
app.component("STimezone", Timezone);
app.component("STextArea", TextArea);
app.component("STextField", TextField);
app.component("SDatePicker", DatePicker);
app.component("STimePicker", TimePicker);
app.component("SColorPicker", ColorPicker);
app.component('PulseLoader', PulseLoader);
app.component('SModal', ModalComp);
app.component("FocusLoop", FocusLoop);

app.directive("click-outside", clickOutside);

app.mount('#app')