import { get, post } from "../assets/functions";

export default {
    namespaced: true,
    actions: {
        async activate(_, data) {
            return await post("/invitation/activate");
        },
        async getEmail(_) {
            let url = "/invitation/activate/76c158ab5528d31fb2db9cd997779a43d1a32675";
            return await get(url);
        },
        async vipActivate(_, data) {
            return await post("/vip/activate", data);
        },
        async contact(_, data) {
            return await post("/contact", data);
        },
        async invitation(_, data) {
            return await post("/invitation", data);
        },
        async vip(_, data) {
            return await post("/vip", data);
        },
        async login(_, data) {
            return await post("/login", data);
        },
        async invite(_, data) {
            return await get(data);
        },
        async room(_, data) {
            return await get(data);
        },
        async register(_, data) {
            return await post("/register", data);
        },
    },
};
